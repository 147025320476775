<script setup lang="ts">
import { profileDD } from "@/_mockApis/headerData";
import { userRole } from '@/plugins/vuetify'

const { logout } = useStrapiAuth()
const router = useRouter()
const user = useStrapiUser()

const onClick = () => {
  logout()
  router.push('/auth/login')
}
</script>

<template>

<v-menu :close-on-content-click="false">
    <template v-slot:activator="{ props }">
      <v-btn class="custom-hover-primary" variant="text" v-bind="props" icon>
        <v-avatar size="35">
          <img src="/images/profile/user-4.jpg" width="35" alt="Julia" />
        </v-avatar>
      </v-btn>
    </template>
    <v-sheet rounded="md" width="360" elevation="10">
      <div class="px-8 pt-6">
        <h6 class="text-h5 font-weight-medium">{{ $t('UserProfile') }}</h6>
        <div class="d-flex align-center mt-4 pb-6">
          <v-avatar size="80">
            <img src="/images/profile/user-4.jpg" width="80" />
          </v-avatar>
          <div class="ml-3">
            <h6 class="text-h6 mb-n1">{{ user?.username }}</h6>
            <span class="text-subtitle-1 font-weight-regular textSecondary">{{ userRole }}</span>
            <div class="d-flex align-center mt-1">
              <MailIcon size="18" stroke-width="1.5" />
              <span
                class="text-subtitle-1 font-weight-regular textSecondary ml-2">{{ user?.email }}</span>
            </div>
          </div>
        </div>
        <v-divider></v-divider>
      </div>
      <perfect-scrollbar style="height: calc(100vh - 70px); max-height: 70px">
        <v-list class="py-0 theme-list" lines="two">
          <v-list-item
            v-for="item in profileDD"
            :key="item.title"
            class="py-4 px-8 custom-text-primary"
            :to="item.href"
          >
            <template v-slot:prepend>
              <v-avatar
                size="48"
                color="primary"
                rounded="md"
              >
                <v-img
                  :src="item.avatar"
                  width="24"
                  height="24"
                  :alt="item.avatar"
                />
              </v-avatar>
            </template>
            <div>
              <h6 class="text-subtitle-1 font-weight-bold mb-2 custom-title">
                {{ item.title }}
              </h6>
            </div>
            <p class="text-subtitle-1 font-weight-regular textSecondary">
              {{ item.subtitle }}
            </p>
          </v-list-item>
        </v-list>
      </perfect-scrollbar>
      <div class="pt-4 pb-6 px-8 text-center">
        <v-btn @click="onClick" color="primary" variant="outlined" block to="">{{ $t('Logout') }}</v-btn>
      </div>
    </v-sheet>
  </v-menu>
</template>