import {
    StarIcon,
    UserStarIcon,
    CashIcon,
    Receipt2Icon,
    ToolIcon,
    DashboardIcon,
    VocabularyIcon,
    NotebookIcon,
    Message2Icon,
    ReservedLineIcon,
    HomeStarIcon,
    DeviceDesktopAnalyticsIcon
} from "vue-tabler-icons";

export interface menu {
  header?: string;
  title?: string;
  icon?: any;
  to?: string;
  chip?: string;
  chipBgColor?: string;
  chipColor?: string;
  chipVariant?: string;
  chipIcon?: string;
  children?: menu[];
  disabled?: boolean;
  type?: string;
  subCaption?: string;
  show?: boolean;
}

import { userRole } from '@/plugins/vuetify'


const sidebarItem: menu[] = [
    { header: 'Indicadores' },
    {
        title: 'Residencial',
        icon: DeviceDesktopAnalyticsIcon,
        to: '/dashboards/residencial',
        show: true
    },
    {
        title: 'Consumo',
        icon: DashboardIcon,
        to: '/dashboards/consumos',
        show: true
    },

    { header: "Residencial" },
    {
        title: "MenuArg110",
        icon: NotebookIcon,
        to: '/arg100/arg110/main',
        show: true
    },
    {
        title: "MenuArg120",
        icon: HomeStarIcon,
        to: '/arg100/arg120/main',
        show: true
    },
    {
        title: "MenuUsers",
        icon: UserStarIcon,
        to: '/user/table',
        show: userRole === 'Administrador'
    },
    {
        title: "MenuArg140",
        icon: CashIcon,
        to: '/arg100/arg140/main',
        show: true
    },
    {
        title: "MenuArg150",
        icon: Receipt2Icon,
        to: '/arg100/arg150/main',
        show: true
    },
    {
        title: "MenuArg160",
        icon: Message2Icon,
        to: '/arg100/arg160/main',
        show: true
    },
    {
        title: "MenuArg170",
        icon: ReservedLineIcon,
        to: '/arg100/arg170/main',
        show: true
    },
    {
        title: "MenuArg190",
        icon: ToolIcon,
        to: '/arg100/arg190/main',
        show: true
    },
    { header: "Documentacion" },
    {
        title: "UserManual",
        icon: VocabularyIcon,
        to: 'https://www.liah-automation.app/residencial/manual',
        type: 'external',
        show: true
    },
    {
        title: "LIAH",
        icon: StarIcon,
        to: 'https://www.liah-automation.app/',
        type: 'external',
        show: true
    }
];

export default sidebarItem;