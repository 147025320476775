<script setup lang="ts">
import { useCustomizerStore } from '@/stores/customizer';
import { pl, zhHans } from 'vuetify/locale'
const customizer = useCustomizerStore();
const title = ref("LIAH - Soluciones en Industria IoT & Automatizacion");
useHead({
  meta: [{ content: title }],
  titleTemplate: (titleChunk) => {
    return titleChunk
      ? `${titleChunk} - LIAH - Soluciones en Industria IoT & Automatizacion`
      : "LIAH - Soluciones en Industria IoT & Automatizacion";
  },
});
</script>

<template>
    <!-----RTL LAYOUT------->
    <v-locale-provider  v-if="customizer.setRTLLayout"  rtl > 
        <v-app
            :theme="customizer.actTheme"
            :class="[
                customizer.actTheme,
                customizer.mini_sidebar ? 'mini-sidebar' : '',
                customizer.setHorizontalLayout ? 'horizontalLayout' : 'verticalLayout',
                customizer.setBorderCard ? 'cardBordered' : ''
            ]"
        >

             <!---Customizer location left side--->
             <v-navigation-drawer app temporary elevation="10" location="left" v-model="customizer.Customizer_drawer" width="320" class="left-customizer">
                
             </v-navigation-drawer>
            <LcFullVerticalSidebar v-if="!customizer.setHorizontalLayout" />
            <LcFullVerticalHeader v-if="!customizer.setHorizontalLayout" />
            <LcFullHorizontalHeader v-if="customizer.setHorizontalLayout" />
            <LcFullHorizontalSidebar v-if="customizer.setHorizontalLayout" />
            <v-main>
               <v-container fluid class="page-wrapper pb-sm-15 pb-10">
                    <div :class="customizer.boxed ? 'maxWidth' : ''">
                        <NuxtPage />
                        
                    </div>
                </v-container>
            </v-main>
        </v-app>
    </v-locale-provider>

    <!-----LTR LAYOUT------->
    <v-locale-provider  v-else> 
        <v-app
            :theme="customizer.actTheme"
            :class="[
                customizer.actTheme,
                customizer.mini_sidebar ? 'mini-sidebar' : '',
                customizer.setHorizontalLayout ? 'horizontalLayout' : 'verticalLayout',
                customizer.setBorderCard ? 'cardBordered' : ''
            ]"
        >

             <!---Customizer location right side--->
             
            <LcFullVerticalSidebar v-if="!customizer.setHorizontalLayout" />
            <LcFullVerticalHeader v-if="!customizer.setHorizontalLayout" />
            <LcFullHorizontalHeader v-if="customizer.setHorizontalLayout" />
            <LcFullHorizontalSidebar v-if="customizer.setHorizontalLayout" />
            <v-main>
               <v-container fluid class="page-wrapper pb-sm-15 pb-10">
                    <div :class="customizer.boxed ? 'maxWidth' : ''">
                        <NuxtPage />
                        
                    </div>
                </v-container>
            </v-main>
        </v-app>
    </v-locale-provider>
</template>
